import React from "react";
import PreislandingLayout, {
  ProductListContext,
} from "../../layouts/PreislandingLayout";
import ProductsTable from "../../components/products-table/ProductsTable";
import PriceComparisonBanner from "../../components/price-comparison-banner/PriceComparisonBanner";
import SEOComponent from "../../components/Shared/Seo";
import PriceFAQ from "../../components/price-faq/PriceFAQ";
const KabelpreisePage = () => {
  const { Consumer } = ProductListContext;
  return (
    <PreislandingLayout>
      <SEOComponent page="kabelpreise" />
      <Consumer>
        {value => (
          <ProductsTable
            productList={value.productList}
            categoryId={80}
            name="Kabelpreise"
          />
        )}
      </Consumer>
      <PriceComparisonBanner />

      <PriceFAQ page="kabelpreise" />
    </PreislandingLayout>
  );
};

export default KabelpreisePage;
